import React from 'react';
import { Box, Flex } from '../../../Layout';
import CongestionOverviewGrid from './CongestionOverviewGrid';
import PortCongestionMap from './PortCongestionMap';
import CongestionByRegionChart from './CongestionByRegionChart';
import PortCongestionGrid from './PortCongestionGrid';
import PortCongestionDetail from './PortCongestionDetail';
import DateBox from 'devextreme-react/date-box';
import ODataStore from 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source';
import {oDataStoreDefaultSetting} from '../../oDataStoreDefaultSetting';

class PortCongestion extends React.Component {
    constructor(props) {
      super(props);
      this.showHideDetail = this.showHideDetail.bind(this);
      this.congestionGridRef = React.createRef()  ;
      this.congestionDetailRef = React.createRef()  ;
      var thisObj = this;
      this.CongestionPeriodSource = new DataSource({
        store: new ODataStore({
          url: `${process.env.GATSBY_ODATA_PUBLIC_SERVER_URL}/congestion_Period`,
          onLoaded: function (result) {
            thisObj.setState({dateValue: new Date(result[0].maxDate), dateNow: new Date(result[0].maxDate), dateMin: new Date(result[0].minDate)});
          },
          ...oDataStoreDefaultSetting
        })
      }); 
      this.CongestionPeriodSource.load();

      //this.dateNow = this.addDays(new Date((new Date()).toDateString()), -1);
      //this.dateMin = this.addDays(this.dateNow,-27);
      this.onDateValueChanged = this.onDateValueChanged.bind(this);
      this.load = this.load.bind(this);

      this.state = {
        dateValue: null,
        visibleDetail: null
      };

      this.setCongestionOverviewGrid = (ref) => {
        if (ref != null)
            this.congestionOverviewGrid = ref;
      };
      this.setPortCongestionMap = (ref) => {
        if (ref != null)
            this.portCongestionMap = ref;
      };
      this.setPortCongestionGrid = (ref) => {
        if (ref != null)
            this.portCongestionGrid = ref;
      };
      this.setCongestionByRegionChart = (ref) => {
        if (ref != null)
            this.congestionByRegionChart = ref;
      };
      this.setCongestionDetailRef = (ref) => {
        if (ref != null)
            this.congestionDetailRef = ref;
      };
      this.setCongestionGridRef = (ref) => {
        if (ref != null)
            this.congestionGridRef = ref;
      };
    }
    addDays(baseDate, days) {
      var date = new Date(baseDate.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    }
   
    componentDidUpdate() {
      if (this.toScrollObj === this.congestionGridRef) {
        window.scrollTo(0, 0);
        //this.toScrollObj.scrollTo();
        
      }
      this.toScrollObj = null;
    }

    onDateValueChanged(e) {
      this.setState({
        dateValue: e.value,
      });
      this.load(e.value);
    }    
    load(filter) {
      let newFilter;
      if (filter) {
        newFilter = [[['date','>=',new Date(filter.toDateString())],'and',['date','<',this.addDays(new Date(filter.toDateString()),1)]]];
      }
      if (newFilter && this.congestionOverviewGrid) {
        this.congestionOverviewGrid.load(newFilter, filter);
      }
      
      if (newFilter && this.portCongestionMap) {
        this.portCongestionMap.load(newFilter, filter);
      }
      if (newFilter && this.portCongestionGrid) {
        this.portCongestionGrid.load(newFilter, filter);
      }
                
    }

    showHideDetail(show, portGroup) {
      
      if (show)
        this.toScrollObj = this.congestionDetailRef;
      else  
        this.toScrollObj = this.congestionGridRef;      
      this.setState({visibleDetail:show, portGroup: portGroup});
    }


    render() {
      return (
      <Flex wrap={['wrap', 'wrap','nowrap']}>
      <Box width={[1, 1, 1]} m={[0,0,'2rem']}>
      <div style={this.state.visibleDetail?{display: 'none'}:{}}>
        
        
        <Flex wrap={['nowrap', 'nowrap','nowrap']}>
        <h2 style={{marginRight:"1REM"}}>Data as at:</h2>

        <div style={{marginTop:"30px"}}>
        <DateBox width={"120px"}
                value={this.state.dateValue}
                min={this.state.dateMin}
                max={this.state.dateNow}
                onValueChanged={this.onDateValueChanged} />        
        </div>
        </Flex>
        {this.state.dateNow && <>
        <CongestionOverviewGrid defaultDate={this.state.dateNow} ref={this.setCongestionOverviewGrid} firebase={this.props.firebase}/>
        <Flex wrap={['wrap', 'wrap','nowrap']}>
          <Box width={[1,1,0.4]} height={[400,400,630]} mr={[0,0,'1rem']}>
            <PortCongestionMap ref={this.setPortCongestionMap} defaultDate={this.state.dateNow} firebase={this.props.firebase} showHideDetailCallback={this.showHideDetail}/>
          </Box>
          <Box width={[1,1,0.6]}>
            <div ref={this.setCongestionGridRef}>
              <PortCongestionGrid ref={this.setPortCongestionGrid} defaultDate={this.state.dateNow}firebase={this.props.firebase} showHideDetailCallback={this.showHideDetail}/>
            </div>
          </Box>
        </Flex>
        </>}
        <CongestionByRegionChart ref={this.setCongestionByRegionChart} firebase={this.props.firebase}/>
        
      </div>
      <div ref={this.setCongestionDetailRef}>
      {this.state.visibleDetail && <PortCongestionDetail defaultPortGroup={this.state.portGroup} showHideDetailCallback={this.showHideDetail} firebase={this.props.firebase}/>}
      </div>
      
      </Box>
      </Flex>
      );  
    }
  }

  export default PortCongestion;